<template>
	<section>
		<div class="appt-detail">
			<div class="appt-detail__heading">
				<!-- Admin View -->
				<template v-if="isAdmin">
					<div class="d-flex flex-wrap">
						<h5>{{ $t("AppointmentDetail.ApptInfo") }}</h5>
						<CButton v-bind="{ variant: 'outline' }" color="info" @click="gotoUpdateForm">{{
							$t("Button.Update")
						}}</CButton>
					</div>
				</template>

				<!-- Success view -->
				<template v-else-if="isSuccess">
					<div class="d-flex flex-wrap justify-content-between">
						<h1>{{ $t("AppointmentSuccess.Appointment") }}</h1>
						<router-link
							:to="{
								name: 'CustomerSearchForm',
							}"
							class="align-self-center"
						>
							{{ $t("Appointment.FindSchedule") }}
						</router-link>
					</div>
					<div class="appt-detail__sub-heading">
						<p>{{ $t("AppointmentSuccess.RegisterInfo") }}</p>
						<p>{{ $t("AppointmentSuccess.PleaseSave") }}</p>
					</div>
				</template>

				<!-- Detail view -->
				<template v-else>
					<div class="d-flex flex-wrap justify-content-between">
						<h1>{{ $t("AppointmentDetail.SearchResult") }}</h1>
						<router-link
							:to="{
								name: 'CustomerSearchForm',
							}"
							class="align-self-center"
						>
							{{ $t("Appointment.FindSchedule") }}
						</router-link>
					</div>
					<p>{{ $t("AppointmentDetail.AppointmentInfo") }}</p>
				</template>
			</div>

			<hr v-if="!isAdmin" class="h-divider" />

			<div class="appt-detail__container">
				<!-- Appointment code -->
				<div class="form-group">
					<label class="form-group__label">{{ $t("AppointmentSearch.ApptCode") }}</label>
					<div class="form-group__input">{{ appointmentForm.code }}</div>
				</div>

				<!-- Status -->
				<div class="form-group">
					<label class="form-group__label">{{ $t("AppointmentForm.Status") }}</label>
					<div class="form-group__input font-weight-bold">{{ computedStatus }}</div>
				</div>

				<!-- Customer type -->
				<div class="form-group">
					<label class="form-group__label">{{ $t("AppointmentForm.CustomerType") }}</label>
					<div class="form-group__input">{{ computedCustomerType }}</div>
				</div>

				<!-- Customer name -->
				<div class="form-group">
					<label class="form-group__label">{{ $t("AppointmentForm.Name") }}</label>
					<div class="form-group__input">{{ get(appointmentForm, "customer.fullName") }}</div>
				</div>

				<!-- Customer phone -->
				<div class="form-group">
					<label class="form-group__label">{{ $t("AppointmentForm.Phone") }}</label>
					<div class="form-group__input">{{ get(appointmentForm, "customer.phone") }}</div>
				</div>

				<!-- Customer year of birth -->
				<div class="form-group">
					<label class="form-group__label">{{ $t("AppointmentForm.YearOfBirth") }}</label>
					<div class="form-group__input">{{ get(appointmentForm, "customer.birthYear") }}</div>
				</div>

				<!-- Contact -->
				<div v-if="appointmentForm.contactInfo" class="form-group">
					<label class="form-group__label">{{ $t("AppointmentForm.Contact") }}</label>
					<div class="form-group__input">
						<span class="contact-icon"
							><img v-lazy="get(computedContactInfo, 'img')" alt class="logo" />{{
								get(computedContactInfo, "title")
							}}</span
						>
						{{ appointmentForm.contactInfo }}
					</div>
				</div>

				<!-- Services -->
				<div class="form-group service">
					<label class="form-group__label">{{ $t("AppointmentForm.Services") }}</label>
					<div class="form-group__input">
						<div
							v-for="service in appointmentForm.requestedServices"
							:key="service.id"
							class="mb-1"
						>
							<p>{{ service.name }}</p>
						</div>
					</div>
				</div>

				<!-- Clinics -->
				<div class="form-group clinic">
					<label class="form-group__label"
						>{{ $t("AppointmentForm.Clinics") }}
						<div><img v-lazy="appointmentForm.clinic.logo" alt class="logo clinic-img" /></div>
					</label>
					<div class="form-group__input panel">
						<span class="clinic__name">{{ appointmentForm.clinic.name }}</span>
						<small class="clinic__address">{{ appointmentForm.clinic.address }}</small>
						<p
							><span class="panel__label">{{ $t("AppointmentForm.WorkingHours") }}: </span
							><span class="panel__des">{{ appointmentForm.clinic.workingHour }}</span></p
						>
						<p
							><span class="panel__label">{{ $t("AppointmentForm.Phone") }}: </span
							><span class="panel__des">{{ appointmentForm.clinic.phone }}</span></p
						>
						<p
							><span class="panel__label">{{ $t("AppointmentForm.Website") }}: </span
							><a :href="appointmentForm.clinic.website" target="_blank" class="panel__link">{{
								appointmentForm.clinic.website
							}}</a></p
						>
					</div>
				</div>

				<!-- Date time -->
				<div class="form-group date-time">
					<label class="form-group__label">{{ $t("AppointmentForm.DateTime") }}</label>
					<div class="form-group__input"
						>{{ computedPlanedDate }}
						<img v-lazy="calendar" alt class="logo" />
					</div>
				</div>

				<!-- Note -->
				<div class="form-group">
					<label class="form-group__label">{{ $t("AppointmentForm.Note") }}</label>
					<div class="form-group__input">{{ appointmentForm.note }}</div>
				</div>

				<!-- SMS -->
				<div v-if="!isAdmin" class="form-group">
					<label class="form-group__label">
						<span class="label-sms">{{ $t("AppointmentForm.ReceivedSMS") }}</span>
					</label>
					<div class="form-group__input">
						<div v-for="sms in computedSMS" :key="sms">{{ sms }}</div>
					</div>
				</div>
			</div>

			<hr v-if="!isAdmin" class="h-divider" />

			<div v-if="!isAdmin" class="appt-detail__action text-right">
				<CButton class="btn-pink btn-ghost-pink" @click="showApptCancelModal">{{
					$t("AppointmentSearch.CancelSchedule")
				}}</CButton>
				<CButton color="info" class="btn-finish" @click="goToApptForm">{{
					$t("AppointmentForm.NewServiceAppt")
				}}</CButton>
			</div>
		</div>
	</section>
</template>

<script>
import Swal from "sweetalert2"
import { CONTACT_METHOD, HIV_RESULTS, APPT_STATUS, CUSTOMER_TYPE } from "@/shared/plugins/constants"
import phone from "@/assets/img/phone-call.svg"
import messenger from "@/assets/img/messenger.svg"
import zalo from "@/assets/img/zalo.svg"
import viber from "@/assets/img/viber.svg"
import skype from "@/assets/img/skype.svg"
import whatsapp from "@/assets/img/whatsapp.svg"
import email from "@/assets/img/gmail.svg"
import calendar from "@/assets/img/calendar.svg"
import { createNamespacedHelpers } from "vuex"
const { mapState } = createNamespacedHelpers("serviceAppointment")
import { get, filter } from "lodash-es"
import moment from "moment"

export default {
	name: "DetailForm",

	props: {
		isAdmin: {
			type: Boolean,
			default: false,
		},
		isSuccess: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			calendar,
			clientDateFormat: process.env.VUE_APP_CLIENT_DATE_FORMAT,

			appointmentForm: {
				code: null,
				status: null,
				fullName: null,
				phoneNumber: null,
				birthYear: null,
				contactTool: { title: "Email", img: email, value: CONTACT_METHOD.EMAIL },
				contactInfo: null,
				planedDate: null,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
				note: null,
				clinic: {},
				requestedServices: [],
				hivTestResult: null,
				smsRemind: false,
				smsFeedback: false,
			},

			contactMethods: [
				{ title: "Phone", img: phone, value: CONTACT_METHOD.PHONE },
				{ title: "Messenger", img: messenger, value: CONTACT_METHOD.MESSENGER },
				{ title: "Zalo", img: zalo, value: CONTACT_METHOD.ZALO },
				{ title: "Viber", img: viber, value: CONTACT_METHOD.VIBER },
				{ title: "Skype", img: skype, value: CONTACT_METHOD.SKYPE },
				{ title: "Whatsapp", img: whatsapp, value: CONTACT_METHOD.WHATSAPP },
				{ title: "Email", img: email, value: CONTACT_METHOD.EMAIL },
			],
		}
	},

	computed: {
		...mapState(["recentAppt", "services"]),

		computedCustomerType: function () {
			return {
				[CUSTOMER_TYPE.PREP]: this.$t("ApptCustomerType.PrEP"),
				[CUSTOMER_TYPE.NORMAL]: this.$t("ApptCustomerType.Normal"),
				[CUSTOMER_TYPE.UNOFFICIAL]: this.$t("ApptCustomerType.Unofficial"),
			}[get(this.appointmentForm, "customer.customerType")]
		},

		computedStatus: function () {
			return {
				[APPT_STATUS.PENDING]: this.$t("AppointmentDetail.Pending"),
				[APPT_STATUS.CUSTOMER_CANCEL]: this.$t("AppointmentDetail.Cancel"),
				[APPT_STATUS.COMPLETE]: this.$t("AppointmentDetail.Complete"),
			}[this.appointmentForm.status]
		},

		computedContactInfo: function () {
			return filter(this.contactMethods, { value: this.appointmentForm.contactTool })[0]
		},

		computedPlanedDate: function () {
			const datetime = moment(this.appointmentForm.planedDate)
			if (datetime.isValid()) return datetime.format(this.clientDateFormat)

			return null
		},

		computedHivResult: function () {
			return {
				[HIV_RESULTS.NEGATIVE]: this.$t("ApptHIVResult.Negative"),
				[HIV_RESULTS.POSITIVE]: this.$t("ApptHIVResult.Positive"),
				[HIV_RESULTS.NOT_AVAILABLE]: this.$t("ApptHIVResult.NotAvailable"),
			}[this.appointmentForm.hivTestResult]
		},

		computedSMS: function () {
			const { customer } = this.appointmentForm
			const configSMS = []

			if (!customer.smsRemind && !customer.smsFeedback)
				configSMS.push(this.$t("AppointmentForm.DontNeed"))
			else {
				customer.smsRemind && configSMS.push(this.$t("AppointmentForm.ReminderAppt"))
				customer.smsFeedback && configSMS.push(this.$t("AppointmentForm.ServiceRatingPrep"))
			}

			return configSMS
		},

		isStatusFinished: function () {
			return this.appointmentForm.status === APPT_STATUS.COMPLETE
		},
	},

	watch: {
		recentAppt: function () {
			this.setUpdata()
		},
	},

	created() {},

	methods: {
		get,

		setUpdata() {
			this.appointmentForm = { ...this.recentAppt }
		},

		goToApptForm() {
			this.$emit("new-appt")
		},

		gotoUpdateForm() {
			this.$emit("update-appt", this.appointmentForm)
		},

		showApptCancelModal() {
			Swal.fire({
				text: this.$t("AppointmentDetail.CancelMessage"),
				showCancelButton: true,
				confirmButtonColor: "#3A99D7",
				cancelButtonColor: "#ED3089",
				confirmButtonText: this.$t("Button.Submit"),
				cancelButtonText: this.$t("Button.Cancel"),
				reverseButtons: true,
				backdrop: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.$emit("cancel-appt", this.appointmentForm)
				}
			})
		},

		getResultValue(result) {
			const value = this.appointmentForm.examinationResult.find(element => {
				if (element.code === result.code && element.serviceId === result.serviceId) return element
			})

			return value.result
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.clinic-img {
	width: 100%;
	height: 120px;
	-o-object-fit: contain;
	object-fit: contain;
	max-width: 150px;
}

.label-sms {
	display: block;

	@media (min-width: $m) {
		width: 85%;
	}
	@media (min-width: $l) {
		width: 70%;
	}
	@media (min-width: $xxl) {
		width: 50%;
	}
}
</style>
